import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { IRegion } from "../../store/regions/regions.reducer";

import { RootState } from "../../store";
import { StatisticDataType } from "../../store/statistics/statistics.reducer";

import {
  RatingContainer,
  RatingList,
  RatingNone,
  RatingTitle,
} from "./Rating.styled";

import RatingItem from "./RatingItem";
import LegendChart from "./LegendChart";

type Selector<S> = (state: RootState) => S;

interface IProfessionDemandChart {
  title: string;
  className?: string;
  region?: StatisticDataType;
  city?: StatisticDataType;
  selector: Selector<RootState | IRegion[] | any>;
  action: any;
}

const ProfessionDemandChart: React.FC<IProfessionDemandChart> = ({
  title,
  className,
  selector,
}) => {
  const statistics = useSelector(selector);
  const rating = useMemo(
    () =>
      statistics
        .filter(
          (item: IRegion) =>
            item.result_demand > 0 || item.result_remote_demand > 0
        )
        .slice(0, 10),
    [statistics]
  );

  // Получаем максимальное значение и берем его за 100% длинны графика
  const maxValueSalary = useMemo(
    () => Math.max(...rating.map((item: IRegion) => item.avg_salary)),
    [rating]
  );

  const maxValueDemand = rating[0]
    ? rating[0].result_demand + rating[0].result_remote_demand
    : 0;

  return (
    <RatingContainer className={className}>
      <RatingTitle>
        <h3>{title}</h3>
      </RatingTitle>
      <RatingList>
        {rating.length > 0 ? (
          rating.map((item: IRegion) => (
            <RatingItem
              key={item.res_id}
              bar={item}
              maxDemand={maxValueDemand}
              maxSalary={maxValueSalary}
              rowId="res_id"
            />
          ))
        ) : (
          <RatingNone>Нет данных</RatingNone>
        )}
      </RatingList>
      {rating.length > 0 && <LegendChart />}
    </RatingContainer>
  );
};

export default React.memo(ProfessionDemandChart);
