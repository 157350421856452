import { useCallback, useEffect, useState } from "react";
import { IOptions } from "../../types/NetworkState";

import { StatisticAPI } from "../../services/statistics.services";

import { Spin } from "antd";
import { Line, LineConfig } from "@ant-design/charts";
import { ChartSubtitle, ChartTitle, ChartWrapper } from "./Charts.styled";

const DynamicVacancies = (options: IOptions) => {
  const { position_id } = options;
  const [data, setData] = useState<any[]>([]);

  const asyncFetch = useCallback(async () => {
    try {
      // Получаем данные с сервера
      const response = await StatisticAPI.fetchVacancies({
        special_id: position_id,
      });
      // Если фильтр включен, выводим дополнительную линию
      let data = response.reduce<any[]>(
        (acc, item) => {
          return acc.concat([
            {
              name: "Все вакансии",
              period: item.period,
              vacancies: item.filtered_vacancies,
            },
          ]);
        },
        [position_id]
      );
      setData(data);
    } catch (error) {}
  }, [position_id]);

  useEffect(() => {
    asyncFetch();
  }, [asyncFetch]);

  const config: LineConfig = {
    data,
    height: 300,
    xField: "period",
    yField: "vacancies",
    seriesField: "name",
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };

  return (
    <ChartWrapper className="kios-professional-cart-wrapper">
      {data.length > 0 ? (
        <>
          <ChartTitle>Динамика вакансий</ChartTitle>
          <ChartSubtitle>Количество вакансий, шт.</ChartSubtitle>
          <Line {...config} />
        </>
      ) : (
        <Spin size="large" />
      )}
    </ChartWrapper>
  );
};

export default DynamicVacancies;
