import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ActivityAPI } from "../../api/activity.api";
import { ConfigResponse, IntegrationAPI } from "../../api/integration.api";
import { ProfessionAPI, ProfessionResponse } from "../../api/professions.api";
import { Result } from "../../types/Result/Result";
import { Profession } from "../../dataset/professionsDataset";

interface IDiagnostic {
  config: ConfigResponse;
  selectedProfession?: ProfessionResponse["profession"];
  profession?: Result;
  professionData?: Profession;
  holland?: Result;
}

const initialState: IDiagnostic = {
  config: {
    hollandUuid: 6,
    professionalOrientationItUuid: 12,
  },
};

export const fetchSelectedProfession = createAsyncThunk(
  "diagnostic/selected_profession",
  async (unti_id: number) => {
    const selected = await ProfessionAPI.fetch(unti_id);
    return selected;
  }
);

export const fetchProfessions = createAsyncThunk(
  "diagnostic/professions",
  async (params: ConfigResponse, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { unti_id } = state.integration;
    const profession = await ActivityAPI.fetchResult(
      params.professionalOrientationItUuid,
      unti_id
    );
    return profession;
  }
);

export const fetchHolland = createAsyncThunk(
  "diagnostic/holland",
  async (params: ConfigResponse, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { unti_id } = state.integration;
    const holland = await ActivityAPI.fetchResult(params.hollandUuid, unti_id);
    return holland;
  }
);

export const fetchConfig = createAsyncThunk(
  "diagnostic/config",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const dispatch = thunkAPI.dispatch;
    const { activity_uuid, unti_id } = state.integration;
    const config = await IntegrationAPI.getConfig(activity_uuid);
    dispatch(fetchHolland(config));
    dispatch(fetchProfessions(config));
    dispatch(fetchSelectedProfession(unti_id));
    return config;
  }
);

const diagnosticSlice = createSlice({
  name: "diagnostic",
  initialState,
  reducers: {
    setHolland: (state, action) => {
      state.holland = action.payload;
    },
    setProfessionalOrientation: (state, action) => {
      state.profession = action.payload;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setProfessionData: (state, action) => {
      state.professionData = action.payload;
    },
    selectProfession: (state, { payload }) => {
      state.selectedProfession = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchConfig.fulfilled,
        (state, action: PayloadAction<ConfigResponse>) => {
          return { ...state, config: action.payload };
        }
      )
      .addCase(fetchHolland.fulfilled, (state, action) => {
        return { ...state, holland: action.payload };
      })
      .addCase(fetchProfessions.fulfilled, (state, action) => {
        return { ...state, profession: action.payload };
      })
      .addCase(
        fetchSelectedProfession.fulfilled,
        (state, { payload }: PayloadAction<ProfessionResponse>) => {
          return { ...state, selectedProfession: payload.profession };
        }
      );
  },
});

const { actions, reducer } = diagnosticSlice;

export const {
  setHolland,
  setProfessionalOrientation,
  setConfig,
  setProfessionData,
  selectProfession,
} = actions;

export default reducer;
