import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./app/app.reducer";
import regionsReducer from "./regions/regions.reducer";
import diagnosticReducer from "./diagnostic/diagnostic.reducer";
import statisticsReducer from "./statistics/statistics.reducer";
import integrationReducer from "./integration/integration.reducer";

export const middleware = (getDefaultMiddleware: any) => {
  return getDefaultMiddleware({
    immutableCheck: true,
    serializableCheck: true,
    thunk: true,
  });
};

const rootReducer = {
  app: appReducer,
  diagnostic: diagnosticReducer,
  integration: integrationReducer,
  regions: regionsReducer,
  statistics: statisticsReducer,
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
