import styled from "styled-components";

export const ChartWrapper = styled.div`
  text-align: center;
  padding: 1rem 0.5rem 3rem;
`;

export const ChartTitle = styled.div`
  text-align: center;
  font-size: 24px;
  line-height: 22px;
  margin-bottom: 0.5rem;
`;

export const ChartSubtitle = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 1rem;
`;
