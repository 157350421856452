import { useEffect } from "react";
//
import { useDispatch, useSelector } from "react-redux";
import { selectDiagnostic } from "./store/diagnostic/diagnostic.selectors";
import { fetchConfig } from "./store/diagnostic/diagnostic.reducer";
//
import { Layout, Spin } from "antd";
import Conclusion from "./components/Conclusion";

function App() {
  const dispatch = useDispatch();
  const { holland, profession } = useSelector(selectDiagnostic);

  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  return (
    <Layout>
      {!holland && (
        <Spin
          className="kios-professional-loading-spinner"
          size="large"
          spinning
        ></Spin>
      )}
      {holland && <Conclusion holland={holland} profession={profession} />}
    </Layout>
  );
}

export default App;
