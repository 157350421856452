import { useCallback } from "react";
import { Profession } from "../../dataset/professionsDataset";

import Box from "../UI/Box";
import { Typography, Space, Button, List } from "antd";

import { ProfessionAPI } from "../../api/professions.api";
import { IntegrationAPI } from "../../api/integration.api";
import { GrayLogAPI } from "../../api/graylog.api";

import { useDispatch, useSelector } from "react-redux";
import { loading } from "../../store/app/app.reducer";
import { selectIntegrationParams } from "../../store/integration/integration.reducer";
import { isProfessionSelected } from "../../store/diagnostic/diagnostic.selectors";
import { selectProfession } from "../../store/diagnostic/diagnostic.reducer";

export const ProfessionDescription = (profession: Profession) => {
  const dispatch = useDispatch();
  const integration = useSelector(selectIntegrationParams);
  const isSelected = useSelector(isProfessionSelected);

  const handleSelectProfession = useCallback(
    async (profession: Profession) => {
      dispatch(loading(true));
      try {
        // Записываем выбранную профессию в базу
        const responseSelectedProfession = await ProfessionAPI.create({
          untiId: integration.unti_id,
          profession: profession.title,
        });
        // делаем её выбранной
        dispatch(selectProfession(responseSelectedProfession.profession));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(loading(false));
      }

      // Отправляем результаты в klaiber
      IntegrationAPI.saveResult(integration, {
        title: profession.title,
        skills: profession.skills,
      });

      // Отправляем логи в Graylog
      GrayLogAPI.sendMessage({
        short_message: "Профориентация (виджет)",
        full_message: "Профориентация (виджет)",
        _body: {
          action: "select-profession",
          untiId: integration.unti_id,
          profession: profession.title,
        },
      });
    },
    [dispatch, integration]
  );

  return (
    <>
      <Box className="kios-professional-profession-description">
        <Box
          className="kios-professional-profession-description-paragraph"
          direction="column"
        >
          <Typography.Title level={2}>{profession.title}</Typography.Title>
          <Typography.Paragraph className="kios-professional-profession-description-summary">
            {profession.description}
          </Typography.Paragraph>
          <Typography.Paragraph className="kios-professional-profession-description-summary"></Typography.Paragraph>
          <Typography.Paragraph className="kios-professional-profession-description-summary">
            Чтобы узнать поподробнее о специальности {profession.title} и
            разобраться, какие компетенции понадобятся вам для работы - нажмите
            кнопку "Записаться на курс". Это короткий, но обязательный шаг для
            получения доступа к лучшим образовательным курсам по специальности!
          </Typography.Paragraph>

          <Typography.Link
            className="kios-professional-profession-description-learn-more"
            href={profession.courseLink}
          >
            Узнать больше
          </Typography.Link>
        </Box>

        <Box
          className="kios-professional-profession-description-button-group"
          direction="column"
        >
          <Space
            className="kios-professional-profession-description-button-group-space"
            direction="vertical"
            size="middle"
          >
            <Button
              className="kios-professional-profession-description-button-group-button"
              shape="round"
              type="primary"
              disabled={isSelected}
              onClick={() => handleSelectProfession(profession)}
            >
              {isSelected ? "Профессия выбрана" : "Выбрать эту профессию"}
            </Button>
          </Space>
        </Box>
      </Box>
      <Box className="kios-professional-profession-required-skills">
        <Typography.Title
          className="kios-professional-profession-required-title"
          level={4}
        >
          Требуемые навыки
        </Typography.Title>

        <List className="kios-professional-profession-required-skills-list">
          {profession.skills.map((skill) => (
            <List.Item key={skill.title}>{skill.title}</List.Item>
          ))}
        </List>
      </Box>
    </>
  );
};
