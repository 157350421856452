import axios from "axios";

export type ProfessionResponse = {
  id: number;
  profession: string;
  untiId: number;
};

const fetch = async (untiId: number) => {
  try {
    const url = `${process.env.REACT_APP_API}/users-professions/untiId/${untiId}`;
    const response = await axios.get<ProfessionResponse>(url);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось загрузить список профессий");
  }
};

const create = async (data: { untiId: number; profession: string }) => {
  try {
    const url = `${process.env.REACT_APP_API}/users-professions/`;
    const response = await axios.post<ProfessionResponse>(url, data);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось создать профессию");
  }
};

export const ProfessionAPI = { fetch, create };
