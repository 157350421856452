import axios from "axios";
import pjson from "../../package.json";

type GrayLogParams = {
  short_message: string;
  full_message: string;
  _body?: any;
  _body_result?: any;
};

const url = "https://graylog.rnd.2035.university/gelf";

const sendMessage = async (params: GrayLogParams) => {
  try {
    return await axios.post(url, {
      version: pjson.version,
      host: window.location.href,
      ...params,
    });
  } catch (error) {
    throw new Error("Ошибка записи данных в логи");
  }
};

export const GrayLogAPI = { sendMessage };
