import { RootState } from "..";
import { createSelector } from "@reduxjs/toolkit";

export const selectDiagnostic = (state: RootState) => state.diagnostic;

export const selectProfessionData = createSelector(
  selectDiagnostic,
  (state) => state.professionData
);

export const selectProfessionDataId = createSelector(
  selectDiagnostic,
  (state) => state.professionData?.id
);

export const isProfessionSelected = createSelector(
  selectDiagnostic,
  (state) => !!state.selectedProfession
);

export const selectProfessionChoice = createSelector(
  selectDiagnostic,
  (state) => state.selectedProfession
);
