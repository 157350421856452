import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { StatisticDataType } from "../../store/statistics/statistics.reducer";
import { selectCountry } from "../../store/statistics/statistics.selectors";

import {
  RatingContainer,
  RatingList,
  RatingNone,
  RatingTitle,
} from "./Rating.styled";

import RatingItem from "./RatingItem";
import LegendChart from "./LegendChart";

type RegionChartProps = {
  region?: StatisticDataType;
};

const RegionChart: React.FC<RegionChartProps> = ({ region }) => {
  const statistics = useSelector(selectCountry);

  const rating = useMemo(
    () =>
      statistics
        .filter(
          (item: any) => item.result_demand > 0 || item.result_remote_demand > 0
        )
        .sort((a, b) => {
          return a.result_demand > b.result_demand ? -1 : 1;
        })
        .slice(0, 10),
    [statistics]
  );

  // Получаем максимальное значение и берем его за 100% длинны графика
  const maxValueSalary = useMemo(
    () => Math.max(...rating.map((item: any) => item.avg_salary)),
    [rating]
  );

  const maxValueDemand = rating[0]
    ? rating[0].result_demand + rating[0].result_remote_demand
    : 0;

  return (
    <RatingContainer>
      <RatingTitle>
        <h3>Востребованность кадров в субъектах РФ</h3>
      </RatingTitle>
      <RatingList>
        {rating.length > 0 ? (
          rating.map((item: any) => (
            <RatingItem
              key={item.res_id}
              bar={item}
              maxDemand={maxValueDemand}
              maxSalary={maxValueSalary}
              rowId={region ? "res_name" : "res_code"}
            />
          ))
        ) : (
          <RatingNone>Нет данных</RatingNone>
        )}
      </RatingList>
      {rating.length > 0 && <LegendChart />}
    </RatingContainer>
  );
};

export default React.memo(RegionChart, (a, b) => a.region === b.region);
