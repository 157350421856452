import axios from "axios";
import { Result } from "../types/Result/Result";

const resultUrl = `${process.env.REACT_APP_API}/results/activityId`;

const fetchResult = async (activityId: number, untiId: number) => {
  try {
    const url = `${resultUrl}/${activityId}/untiId/${untiId}`;
    const response = await axios.get<Result>(url);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось получить результат активности");
  }
};

export const ActivityAPI = { fetchResult };
