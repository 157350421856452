import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface IIntegration {
  token: string;
  unti_id: number;
  activity_uuid: string;
  darkmode: boolean;
}

const root = document.getElementById("webbot");

const url = new URL(window.location.href);

const params = url.searchParams;

const attr = (name: string) => root?.getAttribute(name) || "";

const token = params.get("token") || attr("data-user-token");
const unti_id = params.get("unti_id") || attr("data-unti-id");
const activity_uuid = params.get("activity_uuid") || attr("data-activity-uuid");
const darkmode = !!params.get("darkmode") || !!attr("data-darkmode");

const initialState: IIntegration = {
  token,
  unti_id: Number(unti_id),
  activity_uuid,
  darkmode,
};

const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {},
});

const { reducer } = integrationSlice;

export const selectIntegrationParams = (state: RootState) => state.integration;

export default reducer;
