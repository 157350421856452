import axios from "axios";
import { IOptions } from "../types/NetworkState";

import {
  StatisticDataType,
  StatisticDataBox,
} from "../store/statistics/statistics.reducer";

type Parameter = "skills" | "subjectareas" | "specials";

const request = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
});

request.defaults.headers.common[
  "Authorization"
] = `Bearer ${process.env.REACT_APP_API_KEY}`;

const fetchByTop = async (parameter: Parameter, options: IOptions) => {
  const { limit, position_id } = options;

  let url = "/top";

  try {
    const response = await request.post<StatisticDataType[]>(url, {
      parameter,
      mapMode: "country",
      orderby: "demand",
      limit: limit || 10,
      special_id: position_id,
    });
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

const fetchByCountry = async (options: IOptions) => {
  const { position_id } = options;
  try {
    const response = await request.post<StatisticDataType[]>("/regionlist", {
      special_id: position_id,
    });
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

export type StatisticVacanciesType = {
  filtered_vacancies?: number;
  no_salary_vacancies: number;
  vacancies: number;
  period: string;
};

const fetchVacancies = async (options: IOptions) => {
  const { special_id } = options;
  try {
    const response = await request.get<StatisticVacanciesType[]>(
      "/trends/vacancies",
      {
        params: { special_id: special_id },
      }
    );
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

const fetchSalaries = async (options: IOptions) => {
  const { special_id } = options;
  try {
    const response = await request.get<StatisticDataBox[]>("/trends/salaries", {
      params: {
        special_id: special_id,
      },
    });
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

export const StatisticAPI = {
  fetchByTop,
  fetchByCountry,
  fetchVacancies,
  fetchSalaries,
};
