import { useEffect } from "react";
import { Result } from "../types/Result/Result";
import professionsDataset from "../dataset/professionsDataset";

import Typography from "antd/lib/typography";
import Title from "antd/lib/typography/Title";
import { Layout, List, Space, Button, Spin } from "antd";
import { FileUnknownOutlined, CheckCircleTwoTone } from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";
import {
  selectProfessionData,
  selectDiagnostic,
} from "../store/diagnostic/diagnostic.selectors";
import { setProfessionData } from "../store/diagnostic/diagnostic.reducer";

import Box from "./UI/Box";
import { ProfessionDetails } from "./Professions/ProfessionDetails";

interface ConclusionProps {
  holland: Result;
  profession?: Result;
}

const Conclusion = ({ profession }: ConclusionProps) => {
  // Копируем шкалы профнавигации
  const profScales = profession?.data.scales.concat() || [];

  // Берем 3 композитные шкалы из профнавигации
  const filteredProfScales = profScales
    .sort((a, b) => b.value! - a.value!)
    .filter((scale) => scale.type === "composite")
    .splice(0, 3);

  const selectedProfessionData = useSelector(selectProfessionData);
  const { selectedProfession } = useSelector(selectDiagnostic);

  const dispatch = useDispatch();

  const handleSelectProfession = (title: string) => {
    // Находим в dataset объект с данными по профессии
    const clickedProfession = professionsDataset.find(
      (profession) => profession.title === title
    );

    // Не делаем ничего при клике на ту же профессию
    if (clickedProfession === selectedProfessionData) {
      return;
    }

    // Устанавливаем выбранную профессию в state
    dispatch(setProfessionData(clickedProfession));
  };

  // Установим в state первую профессию из списка
  useEffect(() => {
    filteredProfScales.length &&
      handleSelectProfession(filteredProfScales[0].title);
  }, [filteredProfScales]);

  return (
    <Layout className="kios-professional-widget">
      {selectedProfessionData ? (
        <>
          <Layout.Sider className="kios-professional-sidebar">
            <Typography className="kios-professional-orientation-widget-result">
              <Title
                className="kios-professional-orientation-widget-result__title"
                level={5}
              >
                Поздравляем, вы прошли все диагностические тесты!
              </Title>
              <Typography className="kios-professional-orientation-widget__summary">
                Рекомендательные алгоритмы проанализировали ответы и определили
                подходящие вам востребованные на рынке специальности.
                Ознакомьтесь с ними и выберите ту, по которой вам будут
                предложены курсы и траектория обучения.
              </Typography>
            </Typography>

            <List
              className="kios-professional-sidebar-professions-tabs"
              itemLayout={"vertical"}
            >
              <Space direction="vertical" wrap>
                {filteredProfScales.map((profession) => (
                  <List.Item key={profession.id}>
                    <Button
                      className="kios-professional-sidebar-professions-button"
                      type={"primary"}
                      onClick={() => handleSelectProfession(profession.title)}
                    >
                      {profession.title}
                      {selectedProfession === profession.title && (
                        <CheckCircleTwoTone
                          twoToneColor="#52c41a"
                          style={{ fontSize: "1rem" }}
                        />
                      )}
                    </Button>
                  </List.Item>
                ))}
              </Space>
            </List>
          </Layout.Sider>

          <ProfessionDetails {...selectedProfessionData} />
        </>
      ) : (
        <Box
          className="kios-professional-profession-unselected"
          direction="column"
        >
          <FileUnknownOutlined style={{ fontSize: "3rem" }} />
          <Typography.Paragraph>
            Недостаточно данных для профориентации. Пройдите другие тесты
          </Typography.Paragraph>
        </Box>
      )}
    </Layout>
  );
};

export default Conclusion;

/*
(
        <Box
          className="kios-professional-profession-unselected"
          direction="column"
        >
          <UnorderedListOutlined style={{ fontSize: "3rem" }} />
          <Typography.Paragraph>
            Выберите профессию из списка
          </Typography.Paragraph>
        </Box>
      )
*/
