import { useCallback, useEffect, useState } from "react";
import { IOptions } from "../../types/NetworkState";

import { StatisticAPI } from "../../services/statistics.services";
import { StatisticDataBox } from "../../store/statistics/statistics.reducer";

import { Spin } from "antd";
import { Box, BoxConfig } from "@ant-design/charts";
import { ChartSubtitle, ChartTitle, ChartWrapper } from "./Charts.styled";

const DynamicSalaries = (options: IOptions) => {
  const { position_id } = options;
  const [data, setData] = useState<StatisticDataBox[]>([]);

  const asyncFetch = useCallback(async () => {
    try {
      const response = await StatisticAPI.fetchSalaries({
        special_id: position_id,
      });
      setData(response);
    } catch (error) {}
  }, [position_id]);

  useEffect(() => {
    asyncFetch();
  }, [asyncFetch]);

  const config: BoxConfig = {
    data,
    height: 300,
    xField: "x",
    yField: ["low", "q1", "median", "q3", "high"],
  };

  return (
    <ChartWrapper className="kios-professional-cart-wrapper">
      {data.length > 0 ? (
        <>
          <ChartTitle>Динамика зарплат</ChartTitle>
          <ChartSubtitle>Зарплата, указанная в вакансиях, руб.</ChartSubtitle>
          <Box {...config} />
        </>
      ) : (
        <Spin size="large" />
      )}
    </ChartWrapper>
  );
};

export default DynamicSalaries;
