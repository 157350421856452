import axios from "axios";
import { IIntegration } from "../store/integration/integration.reducer";

const environment = {
  LRSResultFormat: `${process.env.REACT_APP_EXTENSIONS_URL}`,
  proxyHost: "https://bypass.2035.university/api/activity",
  t: "c8d46d341bea4fd5bff866a65ff8aea9",
  production: true,
};

export type ConfigResponse = {
  hollandUuid: number;
  professionalOrientationItUuid: number;
};

const getConfig = async (activity_uuid: IIntegration["activity_uuid"]) => {
  try {
    const url = `${environment.proxyHost}/${activity_uuid}/get_config`;
    const response = await axios.get<ConfigResponse>(url);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось загрузить конфигурацию");
  }
};

const saveResult = async (params: IIntegration, result: any) => {
  const extensions = {
    [environment.LRSResultFormat]: result,
  };
  try {
    const url = `${environment.proxyHost}/${params.activity_uuid}/lrsdata`;
    const response = await axios.post(url, {
      unti_id: params.unti_id,
      token: params.token,
      t: environment.t,
      extensions,
    });
    return response.data;
  } catch (error) {}
};

export const IntegrationAPI = { getConfig, saveResult };
