import { Profession } from "../../dataset/professionsDataset";

import { useDispatch, useSelector } from "react-redux";
import { selectIntegrationParams } from "../../store/integration/integration.reducer";
import { selectProfession } from "../../store/diagnostic/diagnostic.reducer";
import { isProfessionSelected } from "../../store/diagnostic/diagnostic.selectors";
import { loading } from "../../store/app/app.reducer";

import { ProfessionAPI } from "../../api/professions.api";
import { IntegrationAPI } from "../../api/integration.api";
import { GrayLogAPI } from "../../api/graylog.api";

import { Button, Space, Typography, Spin } from "antd";
import Layout from "antd/lib/layout/layout";
import Box from "../UI/Box";

import ProfessionDemandChart from "../Charts/ProfessionDemandChart";
import ProfessionRegionChart from "../Charts/ProfessionRegionChart";
import { selectFieldsStatistics } from "../../store/statistics/statistics.selectors";
import {
  fetchFieldsStatistics,
  fetchCountryStatistics,
} from "../../store/statistics/statistics.reducer";

import { useCallback, useEffect } from "react";
import { ProfessionDescription } from "./ProfessionDescription";
import DynamicVacancies from "../Charts/DynamicVacancies";
import DynamicSalaries from "../Charts/DynamicSalaries";

interface IProfessionDetailsProps extends Profession {}

export const ProfessionDetails: React.VFC<IProfessionDetailsProps> = (
  profession
) => {
  const dispatch = useDispatch();
  const integration = useSelector(selectIntegrationParams);
  const isSelected = useSelector(isProfessionSelected);
  const statistics = useSelector(selectFieldsStatistics);

  const handleSelectProfession = useCallback(
    async (profession: Profession) => {
      dispatch(loading(true));
      try {
        // Записываем выбранную профессию в базу
        const responseSelectedProfession = await ProfessionAPI.create({
          untiId: integration.unti_id,
          profession: profession.title,
        });
        // делаем её выбранной
        dispatch(selectProfession(responseSelectedProfession.profession));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(loading(false));
      }

      // Отправляем результаты в klaiber
      IntegrationAPI.saveResult(integration, {
        title: profession.title,
        skills: profession.skills,
      });

      // Отправляем логи в Graylog
      GrayLogAPI.sendMessage({
        short_message: "Профориентация (виджет)",
        full_message: "Профориентация (виджет)",
        _body: {
          action: "select-profession",
          untiId: integration.unti_id,
          profession: profession.title,
        },
      });
    },
    [dispatch, integration]
  );

  useEffect(() => {
    // Запросим данные статистики
    dispatch(
      fetchFieldsStatistics({
        position_id: profession.id,
      })
    );
    dispatch(
      fetchCountryStatistics({
        position_id: profession.id,
      })
    );
  }, [profession.id, dispatch]);

  return (
    <Layout
      className="kios-professional-profession-content"
      style={{ flexDirection: "column" }}
    >
      <ProfessionDescription {...profession} />
      <Box className="kios-professional-demand-charts-wrapper">
        {statistics.length > 0 ? (
          <>
            <Box className="kios-professional-demand-charts">
              <ProfessionRegionChart />
              <ProfessionDemandChart
                className="kios-professional-demand-chart"
                title="Востребованность в отраслях:"
                selector={selectFieldsStatistics}
                action={fetchFieldsStatistics}
              />
            </Box>
            <Box className="kios-professional-profession-description-button-wrapper">
              <Button
                className="kios-professional-profession-description-button"
                shape="round"
              >
                <Typography.Link href="https://bmk-map.2035.university/">
                  Перейти на БМКарту
                </Typography.Link>
              </Button>
            </Box>
          </>
        ) : (
          <Spin />
        )}
        <Box direction="column">
          <DynamicVacancies position_id={profession.id} />
          <DynamicSalaries position_id={profession.id} />
        </Box>
      </Box>
      <Box className="kios-professional-profession-description-button-group-footer">
        <Space
          className="kios-professional-profession-description-button-group-space"
          direction="horizontal"
          size="middle"
        >
          <Button
            className="kios-professional-profession-description-button-group-button"
            shape="round"
            type="primary"
            disabled={isSelected}
            onClick={() => handleSelectProfession(profession)}
          >
            {isSelected ? "Профессия выбрана" : "Выбрать эту профессию"}
          </Button>
        </Space>
      </Box>
    </Layout>
  );
};
