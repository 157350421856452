export interface Profession {
  id: number;
  title: string;
  description: string;
  courseLink: string;
  skills: {
    title: string;
    goalValue: number;
  }[];
};

const professionsDataset: Profession[] = [
  {
    id: 141,
    title: "Инженер-конструктор",
    description:
      "Специалист проектирует конструкции, инструменты, механизмы, здания, сооружения. Помимо разработки новых конструкций, инженер может модернизировать ранее созданные продукты",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-7/",
    skills: [
      {
        title: "3D моделирование",
        goalValue: 5,
      },
      {
        title: "AutoCAD",
        goalValue: 5,
      },
      {
        title: "SolidWorks",
        goalValue: 4,
      },
      {
        title: "Autodesk Revit",
        goalValue: 5,
      },
      {
        title: "Проектирование систем",
        goalValue: 5,
      },
      {
        title: "Разработка технической документации",
        goalValue: 5,
      },
      {
        title: "Разработка технологических процессов",
        goalValue: 4,
      },
      {
        title: "Составление смет",
        goalValue: 3,
      },
      {
        title: "MS Excel",
        goalValue: 3,
      },
      {
        title: "Настройка и обслуживание оборудования",
        goalValue: 3,
      },
    ],
  },
  {
    id: 352,
    title: "Тестировщик",
    description:
      "Специалист, который следит за качеством программных продуктов, моделирует ситуации, при которых могут возникнуть ошибки в работе, чтобы найти и исправить их.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-15/",
    skills: [
      {
        title: "Разработка тестов",
        goalValue: 5,
      },
      {
        title: "Функциональное тестирование",
        goalValue: 5,
      },
      {
        title: "Автоматизированное тестирование",
        goalValue: 4,
      },
      {
        title: "Нагрузочное тестирование",
        goalValue: 4,
      },
      {
        title: "SQL",
        goalValue: 4,
      },
      {
        title: "Git",
        goalValue: 4,
      },
      {
        title: "Selenium",
        goalValue: 4,
      },
      {
        title: "Java",
        goalValue: 3,
      },
      {
        title: "Python",
        goalValue: 3,
      },
      {
        title: "Linux",
        goalValue: 3,
      },
    ],
  },
  {
    id: 112,
    title: "Графический дизайнер",
    description:
      "Специалист, который занимается оформлением окружающей среды средствами графики, придает продукту, сервису, компании особую стилистическую индивидуальность.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-6/",
    skills: [
      {
        title: "Adobe Photoshop",
        goalValue: 5,
      },
      {
        title: "CorelDRAW",
        goalValue: 5,
      },
      {
        title: "Adobe Illustrator",
        goalValue: 5,
      },
      {
        title: "Adobe InDesign",
        goalValue: 5,
      },
      {
        title: "Разработка дизайна",
        goalValue: 5,
      },
      {
        title: "Разработка макетов",
        goalValue: 5,
      },
      {
        title: "Разработка концепции",
        goalValue: 5,
      },
      {
        title: "Разработка фирменного стиля",
        goalValue: 4,
      },
      {
        title: "Составление презентаций",
        goalValue: 4,
      },
      {
        title: "Разработка логотипов",
        goalValue: 4,
      },
    ],
  },
  {
    id: 28,
    title: "PR менеджер",
    description:
      "Специалист по связям с общественностью, который отвечает за создание и поддержание положительного имиджа компании в глазах потенциальных покупателей, зрителей или избирателей.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-2/",
    skills: [
      {
        title: "Взаимодействие со СМИ",
        goalValue: 4,
      },
      {
        title: "Организация мероприятий",
        goalValue: 5,
      },
      {
        title: "Подготовка материалов",
        goalValue: 4,
      },
      {
        title: "Оценка эффективности",
        goalValue: 5,
      },
      {
        title: "Разработка PR-стратегии",
        goalValue: 5,
      },
      {
        title: "Взаимодействие с агентствами",
        goalValue: 4,
      },
      {
        title: "Формирование имиджа компании",
        goalValue: 5,
      },
      {
        title: "Мониторинг публикаций",
        goalValue: 4,
      },
    ],
  },
  {
    id: 221,
    title: "Менеджер проектов",
    description:
      "Специалист, который управляет проектом и координирует работу команды, чтобы проект был выполнен в указанные сроки и соответствовал всем требованиям со стороны заказчика.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-12/",
    skills: [
      {
        title: "Планирование проекта",
        goalValue: 5,
      },
      {
        title: "Администрирование проекта",
        goalValue: 5,
      },
      {
        title: "Разработка требований",
        goalValue: 4,
      },
      {
        title: "Управление ресурсами",
        goalValue: 5,
      },
      {
        title: "Переговоры",
        goalValue: 5,
      },
      {
        title: "Анализ проектной деятельности",
        goalValue: 4,
      },
    ],
  },
  {
    id: 204,
    title: "Менеджер по продажам",
    description:
      "Специалист, который занимается реализацией товаров или услуг, которые предлагает или производит компания, расширяет круг клиентов и поддерживает партнерские отношения с ними.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-9/",
    skills: [
      {
        title: "Подготовка коммерческих предложений",
        goalValue: 4,
      },
      {
        title: "CRM-система",
        goalValue: 5,
      },
      {
        title: "Ведение договоров",
        goalValue: 5,
      },
      {
        title: "MS Office",
        goalValue: 5,
      },
      {
        title: "Проведение презентации продукта",
        goalValue: 5,
      },
      {
        title: "Расширение клиентской базы",
        goalValue: 5,
      },
      {
        title: "Составление отчетов по продажам",
        goalValue: 4,
      },
    ],
  },
  {
    id: 143,
    title: "Интернет-маркетолог",
    description:
      "Специалист, который разбирается в маркетинге и интернет-технологиях, с их помощью достигает бизнес-целей компании, продвигая ее услуги и товары в сети.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-8/",
    skills: [
      {
        title: "Продвижение бизнеса в сети",
        goalValue: 5,
      },
      {
        title: "Навыки стратегического планирования",
        goalValue: 5,
      },
      {
        title: "Поисковая оптимизация и поисковый маркетинг",
        goalValue: 4,
      },
      {
        title: "Программирование (базовые знания С++)",
        goalValue: 3,
      },
      {
        title: "Анализ данных (Excel, SQL)",
        goalValue: 5,
      },
      {
        title: "Контент-стратегия",
        goalValue: 5,
      },
    ],
  },
  {
    id: 13,
    title: "Data scientist",
    description:
      "Data Science - это методы анализа данных и извлечения из них ценной информации, знаний. Специалист в этой области строит на основе данных модели, помогающие принимать решения бизнесу, науке, решения в повседневной жизни.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-1/",
    skills: [
      {
        title: "Анализ данных",
        goalValue: 5,
      },
      {
        title: "Обработка данных",
        goalValue: 5,
      },
      {
        title: "Разработка моделей",
        goalValue: 5,
      },
      {
        title: "Pandas",
        goalValue: 5,
      },
      {
        title: "Scikit-learn",
        goalValue: 5,
      },
      {
        title: "NumPy",
        goalValue: 5,
      },
      {
        title: "Python",
        goalValue: 4,
      },
      {
        title: "SQL",
        goalValue: 4,
      },
      {
        title: "Apache Spark",
        goalValue: 4,
      },
      {
        title: "Apache Hadoop",
        goalValue: 4,
      },
    ],
  },
  {
    id: 100,
    title: "Бизнес-аналитик",
    description:
      "Специалист в бизнес-анализе работает с большим массивом данных, составляет финансовые модели, анализирует положение компаний на рынке и предлагает конкретные варианты для оптимизации бизнеса.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-4/",
    skills: [
      {
        title: "Разработка документации",
        goalValue: 5,
      },
      {
        title: "Описание бизнес-процессов",
        goalValue: 5,
      },
      {
        title: "Разработка технических заданий",
        goalValue: 4,
      },
      {
        title: "Разработка процессов",
        goalValue: 5,
      },
      {
        title: "Оптимизация процессов",
        goalValue: 5,
      },
      {
        title: "Моделирование бизнес-процессов",
        goalValue: 5,
      },
      {
        title: "Разработка отчетности",
        goalValue: 5,
      },
    ],
  },
  {
    id: 381,
    title: "Финансовый аналитик",
    description:
      "Специалист, который занимается анализом информации и обработкой данных в компании с помощью которых можно  решать вопросы финансового управления, а также выявлять и управлять рисками компании.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-16/",
    skills: [
      {
        title: "MS Excel",
        goalValue: 5,
      },
      {
        title: "Анализ данных",
        goalValue: 5,
      },
      {
        title: "Анализ бизнес-процессов",
        goalValue: 5,
      },
      {
        title: "Анализ экономических показателей",
        goalValue: 5,
      },
      {
        title: "Анализ проектов",
        goalValue: 4,
      },
      {
        title: "Анализ рисков",
        goalValue: 5,
      },
      {
        title: "Оценка эффективности",
        goalValue: 5,
      },
      {
        title: "Подготовка статистической отчетности",
        goalValue: 4,
      },
    ],
  },
  {
    id: 314,
    title: "Программист (Frontend)",
    description: "Программист, занимающийся разработкой пользовательского интерфейса сайтов.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-14/",
    skills: [
      {
        title: "JavaScript",
        goalValue: 5,
      },
      {
        title: "CSS",
        goalValue: 5,
      },
      {
        title: "HTML",
        goalValue: 55,
      },
      {
        title: "Git",
        goalValue: 5,
      },
      {
        title: "React",
        goalValue: 5,
      },
      {
        title: "Angular",
        goalValue: 5,
      },
      {
        title: "Sass",
        goalValue: 5,
      },
      {
        title: "Vue.js",
        goalValue: 5,
      },
      {
        title: "TypeScript",
        goalValue: 4,
      },
      {
        title: "Webpack",
        goalValue: 4,
      },
    ],
  },
  {
    id: 310,
    title: "Программист (Backend)",
    description:
      "Программист, который занимается внутренней организацией работы приложения, сайтов, разрабатывает схемы хранения данных, передачи информации пользователю.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-13/",
    skills: [
      {
        title: "Git",
        goalValue: 5,
      },
      {
        title: "Java",
        goalValue: 5,
      },
      {
        title: "C#",
        goalValue: 5,
      },
      {
        title: "Go",
        goalValue: 5,
      },
      {
        title: "PHP",
        goalValue: 5,
      },
      {
        title: "Python",
        goalValue: 5,
      },
      {
        title: "JavaScript",
        goalValue: 5,
      },
      {
        title: "Node.js",
        goalValue: 5,
      },
      {
        title: "Linux",
        goalValue: 4,
      },
      {
        title: "SQL",
        goalValue: 4,
      },
    ],
  },
  {
    id: 32,
    title: "SMM менеджер",
    description:
      "Social Media Manager — это специалист, занимающийся продвижением бренда, продукта или услуг компании в социальных сетях.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-3/",
    skills: [
      {
        title: "Ведение SMM проектов",
        goalValue: 4,
      },
      {
        title: "Анализ эффективности продвижения",
        goalValue: 5,
      },
      {
        title: "Настройка таргетинга",
        goalValue: 5,
      },
      {
        title: "Разработка SMM-стратегии",
        goalValue: 5,
      },
      {
        title: "Ведение аккаунтов",
        goalValue: 5,
      },
      {
        title: "Взаимодействие с подписчиками",
        goalValue: 5,
      },
      {
        title: "Разработка контента",
        goalValue: 4,
      },
      {
        title: "Размещение контента",
        goalValue: 5,
      },
      {
        title: "Анализ деятельности конкурентов",
        goalValue: 4,
      },
    ],
  },
  {
    id: 207,
    title: "Менеджер по работе с клиентами",
    description:
      "Специалист, занятый активным обслуживанием и сохранением круга клиентов, планированием и реализацией долгосрочных отношений с ними, а также активным привлечением новых клиентов. Также эту позицию часто называют «account-менеджер» или «клиент-менеджер».",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-10/",
    skills: [
      {
        title: "Оформление заявок клиентов",
        goalValue: 4,
      },
      {
        title: "Сопровождение заказов",
        goalValue: 4,
      },
      {
        title: "Выполнение плановых показателей",
        goalValue: 5,
      },
      {
        title: "Прием заказов",
        goalValue: 4,
      },
      {
        title: "Подготовка коммерческих предложений",
        goalValue: 5,
      },
      {
        title: "Контроль дебиторской задолженности",
        goalValue: 4,
      },
      {
        title: "Ведение переговоров",
        goalValue: 5,
      },
      {
        title: "Заключение договоров",
        goalValue: 4,
      },
      {
        title: "Продажа товаров и услуг",
        goalValue: 5,
      },
      {
        title: "Презентация товаров и услуг",
        goalValue: 5,
      },
    ],
  },
  {
    id: 210,
    title: "Менеджер по рекламе",
    description:
      "Специалист, который планирует и проводит рекламные кампании, разрабатывает стратегии продвижения, которые заинтересуют новых клиентов.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-11/",
    skills: [
      { title: "Разработка рекламной стратегии", goalValue: 5 },
      { title: "Взаимодействие с дизайнерами", goalValue: 4 },
      { title: "Взаимодействие с подрядчиками", goalValue: 4 },
      { title: "Продвижение товаров и услуг", goalValue: 5 },
      { title: "Разработка и подготовка рекламных материалов", goalValue: 5 },
      { title: "Реализация рекламной кампании", goalValue: 5 },
      { title: "Организация рекламных мероприятий", goalValue: 5 },
    ],
  },
  {
    id: 35,
    title: "Веб-дизайнер",
    description:
      "Занимается разработкой внешнего вида сайтов, начиная от выбора общей концепции и цветовой гаммы и заканчивая расположением элементов на экране.",
    courseLink: "https://edu.2035.university/course/UNIVERSITY2035/ds-proftr-spb-21-5/",
    skills: [
      {
        title: "Adobe Photoshop",
        goalValue: 5,
      },
      {
        title: "CorelDRAW",
        goalValue: 5,
      },
      {
        title: "Adobe Illustrator",
        goalValue: 5,
      },
      {
        title: "Adobe InDesign",
        goalValue: 5,
      },
      {
        title: "Разработка дизайна",
        goalValue: 5,
      },
      {
        title: "Разработка макетов",
        goalValue: 5,
      },
      {
        title: "Разработка концепции",
        goalValue: 5,
      },
      {
        title: "Разработка фирменного стиля",
        goalValue: 4,
      },
      {
        title: "Составление презентаций",
        goalValue: 4,
      },
      {
        title: "Разработка логотипов",
        goalValue: 4,
      },
    ],
  },
];

export default professionsDataset;
