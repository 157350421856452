import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import store from "./store";
import pjson from "../package.json";
import "./scss/index.scss";

const root = document.getElementById("webbot");

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  root
);

console.log(
  `%c KIOS Professional Orientation widget version: ${pjson.version} `,
  "background-color: #ffdd2d; color: #333;"
);
