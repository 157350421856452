interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  direction?: "row" | "column";
  children: React.ReactNode;
  className?: string;
}

const Box = ({ direction, className, children }: BoxProps) => {
  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: direction || "row",
      }}
    >
      {children}
    </div>
  );
};

export default Box;
