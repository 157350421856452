import axios from "axios";
import { IRegion } from "../store/regions/regions.reducer";

const request = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
});

request.defaults.headers.common[
  "Authorization"
] = `Bearer ${process.env.REACT_APP_API_KEY}`;

const fetch = async () => {
  try {
    const regions = await axios.get<IRegion[]>("/regionlist");
    return Array.isArray(regions.data) ? regions.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке списка регионов");
  }
};

export const RegionAPI = { fetch };
