import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

export const selectStatistics = (state: RootState) => state.statistics;

export const selectFieldsStatistics = createSelector(
  selectStatistics,
  (state) => state.fields
);

export const selectCountry = createSelector(
  selectStatistics,
  (state) => state.country
);
